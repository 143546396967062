import { graphql } from "gatsby"
import React from "react"
import { FaFacebookSquare, FaTwitter, FaInstagram } from "react-icons/fa"
import tw from "tailwind.macro"
import Button from "../components/button"
import Grid from "../components/grid"
import Layout from "../components/layout"
import LeadText from "../components/leadText"
import Masthead from "../components/masthead"
import Panel from "../components/panel"
import SafeLink from "../components/safeLink"
import SEO from "../components/seo"

const Section = tw.section``

const Collaborate = ({ data, location }) => {
  const parentTitle = ""
  const pageTitle = "Collaborate"

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.profMadonsela2.childImageSharp.fluid}
        styling={`min-height:50vh`}
      />
      <Section css={tw`py-10`}>
        <div className="container">
          <h2>We want to engage with you!</h2>
          <LeadText>
            In a complex and rapidly changing world, collaboration and openness
            are vital. We believe in sharing ideas and thinking with our
            partners for mutual benefit and better outcomes. Do you have an idea
            and want to collaborate? We want to talk to you!
          </LeadText>
        </div>
      </Section>
      <Section css={tw`py-20 bg-light-gray-100 shadow-inner`}>
        <div className="container">
          <h2 css={tw`mt-0`}>Common reasons to engage:</h2>
          <Grid>
            <Panel>
              <h3>
                You want to be part of the conversation around social justice
              </h3>
            </Panel>
            <Panel>
              <h3>
                You're looking for a framework with which to measure social
                justice
              </h3>
            </Panel>
            <Panel>
              <h3>
                You're an individual or organisation needing our subject matter
                expertise
              </h3>
            </Panel>
            <Panel>
              <h3>
                You're an academic institution seeking research partnerships
              </h3>
            </Panel>
            <Panel>
              <h3>
                You're interested in sponsoring{" "}
                <SafeLink
                  to="/projects/"
                  css={tw`text-dark-gray-700 hover:text-dark-gray`}
                >
                  our other projects
                </SafeLink>
              </h3>
            </Panel>
          </Grid>
        </div>
      </Section>
      <Section css={tw`py-20`}>
        <div className="container">
          <h2>How to engage</h2>
          <Grid>
            <div>
              <h3>Follow us on social media</h3>
              <p>
                <SafeLink
                  to="https://www.facebook.com/Social-Justice-M-Plan-343817256222320/"
                  css={tw`text-maroon text-xl`}
                >
                  <FaFacebookSquare
                    css={tw`inline mr-2 hover:text-dark-gray text-5xl align-middle`}
                  />
                  Social Justice M-Plan
                </SafeLink>
              </p>
              <p>
                <SafeLink
                  to="https://twitter.com/CFSJ_MPlan"
                  css={tw`text-maroon text-xl`}
                >
                  <FaTwitter
                    css={tw`inline mr-2 hover:text-dark-gray text-5xl align-middle`}
                  />
                  @CFSJ_MPlan
                </SafeLink>
              </p>
              <p>
                <SafeLink
                  to="https://www.instagram.com/socialjusticechairsu/"
                  css={tw`text-maroon text-xl`}
                >
                  <FaInstagram
                    css={tw`inline mr-2 hover:text-dark-gray text-5xl align-middle`}
                  />
                  @socialjusticechairsu
                </SafeLink>
              </p>
            </div>
            <div className="col-span-2">
              <h3>Send us a request for collaboration</h3>
              <form action="https://usebasin.com/f/44697ef058ef" method="POST">
                <label class="block mb-5">
                  <span class="text-dark">Organisation/individual's name:</span>
                  <input
                    class="form-input mt-1 block w-full"
                    name="name"
                    placeholder="John Smith"
                  />
                </label>
                <label class="block mb-5">
                  <span class="text-dark">Website (if available):</span>
                  <input
                    class="form-input mt-1 block w-full"
                    name="website"
                    placeholder="https://www.example.co.za"
                  />
                </label>
                <label class="block mb-5">
                  <span class="text-dark">Contact person email:</span>
                  <input
                    class="form-input mt-1 block w-full"
                    name="email"
                    placeholder="john@smith.com"
                  />
                </label>
                <label for="phone" css={tw`mb-5`}>
                  <span css={tw`block`}>Contact person phone:</span>
                  <input
                    name="phone"
                    type="text"
                    placeholder="+27 72 123 4567"
                    className="form-input mt-1 block w-full"
                  />
                </label>
                <label for="type" css={tw`mb-5`}>
                  <span>
                    <span css={tw`block`}>
                      What kind of collaboration are you primarily interested
                      in?
                    </span>
                  </span>
                  <select name="type" className="form-select">
                    <option>Joint development</option>
                    <option>Sponsorship</option>
                    <option>Funding</option>
                    <option>Research Projects</option>
                  </select>
                </label>
                <label class="block mb-5">
                  <span class="text-dark">Your message</span>
                  <textarea
                    class="form-textarea mt-1 block w-full"
                    rows="3"
                    name="message"
                    placeholder="I'm interested in..."
                  ></textarea>
                </label>
                {/*<label class="flex items-center mb-5">
                  <input
                    type="checkbox"
                    name="privacy-policy-agreed"
                    class="form-checkbox"
                  />
                  <span class="ml-2">
                    I agree to the{" "}
                    <SafeLink
                      to="/privacy-policy/"
                      css={tw`border-b-2 border-primary hover:text-primary`}
                      forceBlank
                    >
                      privacy policy
                    </SafeLink>
                  </span>
  </label>*/}
                <Button type="submit" size="md">
                  Submit &rarr;
                </Button>
              </form>
            </div>
          </Grid>
        </div>
      </Section>
    </Layout>
  )
}

export default Collaborate

export const pageQuery = graphql`
  query {
    profMadonsela: file(relativePath: { eq: "home-profmadonsela.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    profMadonsela2: file(relativePath: { eq: "about-profmadonsela.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
